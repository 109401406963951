import React from 'react';
import { ExtendCSS, CurrentTheme } from 'vcc-ui';
import { parseMarkdown } from '../..';
import RichText from '@vcc-www/rich-text';

type Props = {
  markdown: string;
  extend: ExtendCSS;
  variant?: keyof CurrentTheme['typeScale'];
  internalLinkDomains?: string[];
  enableLists?: boolean;
  enableTracking?: boolean;
  trackEventLabel?: ((linkText: string, linkUrl: string) => string) | string;
  trackEventAction?: string;
  trackEventCategory?: ((linkText: string, linkUrl: string) => string) | string;
};

/**
 *  @deprecated
 *  Use Markdown component from '@vcc-package/text' instead.
 **/
export const Markdown_DEPRECATED_VCCUI: React.FC<
  React.PropsWithChildren<Props>
> = ({
  variant,
  markdown,
  extend,
  internalLinkDomains,
  enableTracking,
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  enableLists,
  ...props
}) => {
  const html = parseMarkdown(
    markdown,
    internalLinkDomains,
    enableLists,
  ).replace(/\\n/gm, '<br>');
  return (
    <RichText
      extend={[markdownCSS(variant), extend]}
      html={html}
      enableTracking={enableTracking}
      trackEventLabel={trackEventLabel}
      trackEventAction={trackEventAction}
      trackEventCategory={trackEventCategory}
      {...props}
    />
  );
};

export default Markdown_DEPRECATED_VCCUI;

const markdownCSS =
  (variant: Props['variant'] = 'bates'): ExtendCSS =>
  ({ theme: { color, typeScale } }) => ({
    ...typeScale[variant].standard.styles,
    '& strong': typeScale[variant].emphasis.styles,
    '& a': {
      ...typeScale[variant]['inline-link'].styles,
      color: color.foreground.secondary,
    },
    '& code': typeScale[variant].standard.styles,
    '& p': {
      margin: 0,
    },
    '& table': {
      backgroundColor: color.foreground.secondary,
      borderSpacing: '1px',
      width: '100%',
      tableLayout: 'fixed',
    },
    '& thead': {
      backgroundColor: color.background.secondary,
    },
    '& tbody': {
      backgroundColor: color.background.secondary,
    },
    '& td': {
      padding: '4px',
    },
    '& th': {
      padding: '4px',
    },
  });
