/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';
import { Tracker, type TrackerOptions } from './Tracker';
import { TrackingContext } from './TrackingProvider';
import { type TrackingData } from './types';

/**
 * Create a tracker that can be used to send analytics events with current
 * tracking context.
 */
export function useTracker(
  hookData?: TrackingData | null,
  trackerOptions?: TrackerOptions,
  options?: { ignoreIfEmptyContext?: boolean },
): Tracker {
  const {
    trackingData: contextData,
    logging,
    forceLowerCase,
  } = useContext(TrackingContext);
  const eventData = { ...contextData, ...hookData };

  const shouldDisable =
    options?.ignoreIfEmptyContext && !Object.keys(contextData || {}).length;
  // Memoize to make sure e.g. `tracker.interaction` is stable across renders,
  // in case it is passed around as a prop to memoized components
  const tracker = useMemo(() => {
    return new Tracker(eventData, {
      logging,
      forceLowerCase,
      disabled: shouldDisable || trackerOptions?.disabled,
      ...trackerOptions,
    });
  }, [
    JSON.stringify(eventData),
    JSON.stringify(trackerOptions),
    logging,
    forceLowerCase,
    shouldDisable,
  ]);

  return tracker;
}
