'use client';
import type { PageViewData } from '@volvo-cars/tracking';
import { useTracker } from '@volvo-cars/tracking';
import { useCallback, useEffect } from 'react';
import { LeadsApp } from '../constants';
import type { PurposeType } from '@vcc-package/leads-utils/api';
import { LeadsApplications } from '@vcc-package/leads-utils/constants';

type useEmbeddableFormsTrackingProps = {
  inDialog: boolean;
  dialogOpen?: boolean;
  appId: LeadsApp;
  purpose?: PurposeType | null;
  isEmbeddableForms?: boolean;
  consumerApp: string | null;
  hasSelection?: boolean;
};

export function useEmbeddableFormsTracking({
  inDialog,
  dialogOpen,
  appId,
  purpose,
  isEmbeddableForms,
  consumerApp,
  hasSelection,
}: useEmbeddableFormsTrackingProps) {
  const track = useTracker();

  const getPageViewData = useCallback(() => {
    const featureFlag = 'isEmbeddableForms=true';
    const pageViewData = {
      pageName: LeadsApplications[appId].pageName,
      formType: LeadsApplications[appId].pageName,
      ...(appId === LeadsApp.QuoteRequest && {
        subPageName: !purpose ? 'sales agent selector' : 'form',
      }),
      pageType: 'form',
      featureFlag,
      featureList: featureFlag,
      consumerApp,
    } as PageViewData;

    return pageViewData;
  }, [appId, purpose, consumerApp]);

  useEffect(() => {
    if (!isEmbeddableForms || (inDialog && !dialogOpen)) return;
    if (appId !== LeadsApp.QuoteRequest && !purpose) return;

    const pageViewData = getPageViewData();
    track.pageView(pageViewData);
    if (appId === LeadsApp.QuoteRequest && !hasSelection) {
      return;
    }
    track.formLoad(pageViewData);
  }, [
    appId,
    track,
    inDialog,
    purpose,
    getPageViewData,
    isEmbeddableForms,
    dialogOpen,
    hasSelection,
  ]);

  return {
    pageView: () => {
      const data = getPageViewData();
      track.pageView(data);
    },

    formLoad: () => {
      const data = getPageViewData();
      track.formLoad(data);
    },
  };
}
