import React from 'react';
import type Props from './TilesBundle.props';
import { animated, useTrail } from 'react-spring';
import { useInView, useBreakpoints } from '@vcc-www/hooks';
import { ResponsiveImage } from '@vcc-package/media';
import { Track } from '@volvo-cars/tracking';
import styles from './TilesBundle.module.css';
import { cssJoin } from '@volvo-cars/css/utils';

const Component: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subHeader,
  tiles,
  imageDensity,
  tabSpacing = false,
  headingTag,
}) => {
  const HeadingTag = headingTag || 'h2';

  return (
    <>
      <div className="flex-col md:items-center md:text-center">
        <HeadingTag className="heading-2">{title}</HeadingTag>
        <p
          className={cssJoin(
            'my-24 lg:mb-32 font-20 text-secondary md:text-center text-secondary',
            styles.subHeader,
          )}
        >
          {subHeader}
        </p>
      </div>
      <TilesContainer
        tiles={tiles}
        tabSpacing={tabSpacing}
        imageDensity={imageDensity}
      />
    </>
  );
};

const TilesContainer: React.FC<{
  tiles: Props['tiles'];
  tabSpacing: Props['tabSpacing'];
  imageDensity?: number;
}> = ({ tiles, tabSpacing }) => {
  const { fromL } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints

  const [inViewRef, inView] = useInView<HTMLDivElement>({
    triggerOnce: true,
    rootMargin: '0px 0px',
  });

  const desktopAnimationStyle = useTrail(tiles.length, {
    to: {
      transform: inView ? 'translateY(0)' : 'translateY(50%)',
    },
    from: {
      transform: 'translateY(50%)',
    },
  });

  return (
    <div
      ref={inViewRef}
      className="flex-col lg:flex-row gap-16 lg:justify-center lg:mb-0 lg:flex-wrap"
    >
      {tiles.map((tile, i) => (
        <animated.div
          className={cssJoin(
            'bg-secondary md:mb-m mb-s flex-col justify-between',
            styles.tile,
            tabSpacing && 'md:mx-48 lg:mx-0',
          )}
          key={i}
          style={{
            ...(fromL ? desktopAnimationStyle[0] : {}),
          }}
        >
          <div className="flex-col flex-shrink flex-grow justify-between items-start stack-24 p-16 lg:py-48">
            <p className="font-20 self-start font-medium">{tile.title}</p>
            <p className="flex-grow flex-shrink text-secondary">
              {tile.description}
            </p>
            <Track
              eventLabel={`${
                tile?.ctaTrackLabel || tile.cta?.text
              } | ${tile.cta?.href}`}
              eventAction="link|click"
            >
              <a
                data-color="accent"
                className="button-text"
                href={tile.cta?.href}
                target={tile.cta?.target}
              >
                {tile.cta?.text}
              </a>
            </Track>
          </div>
          <ResponsiveImage
            alt={tile.image?.alt || ''}
            style={{ marginTop: 'auto' }}
            loading="lazy"
            images={{ sm: tile.image }}
          />
        </animated.div>
      ))}
    </div>
  );
};

export default Component;
