'use client';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { Spacing } from 'src/components/layout/Spacing';
import { Track, TrackingProvider, useTracker } from '@volvo-cars/tracking';
import { ListItem } from '@vcc-package/list-item';
import { useQueries } from 'src/hooks/useQueries';
import { BaseImage } from '@vcc-package/media';
import {
  SalesModelId,
  type Service as ServiceProps,
} from '@vcc-www/api/finance-options/types';
import { useBreakpoints } from '@vcc-www/hooks';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { TitledOverlay } from '@volvo-cars/react-overlay';
import { ServiceDetailsView } from '@vcc-package/finance-details';
import type { LinkEntry } from '@volvo-cars/content-management-client';

interface SharedServicesGridProps {
  title: string;
  body?: string;
  electricLabel?: string;
  cta?: LinkEntry;
  showIncludedLabel?: boolean;
}

export interface ServicesGridProps extends SharedServicesGridProps {
  type: 'servicesGrid';
  salesModel: SalesModelId;
  showIncludedLabel?: boolean;
}

export const ServicesGrid = ({
  title,
  body,
  electricLabel,
  cta,
  salesModel = SalesModelId.Sub,
  showIncludedLabel = true,
}: ServicesGridProps) => {
  const { financeOptions } = useQueries();
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { fromM } = useBreakpoints();
  const translate = useSharedComponentsTranslate();
  const [selectedService, setSelectedService] = useState<ServiceProps>();
  const [selectedPackageService, setSelectedPackageService] =
    useState<
      Omit<ServiceProps, 'items' | 'isElectricOnly' | 'applicableCarModels'>
    >();
  const financeOption = financeOptions[salesModel];

  if (!financeOption) {
    console.error('Services Grid: FinanceOptions data unavailable');
    return null;
  }

  const { services = [] } = financeOption;

  return (
    <TrackingProvider eventCategory="services grid">
      <Spacing>
        <div data-testid="services-grid" className="container">
          <div className="lg:mx-64 lg:px-48">
            <div className="w-sm mx-auto md:text-center">
              <h2 data-testid="services-grid:title" className="heading-1">
                {title}
              </h2>
              {body && (
                <p
                  data-testid="services-grid:body"
                  className="mt-16 md:font-20 text-secondary"
                >
                  {body}
                </p>
              )}
            </div>
            <Services
              services={services}
              showIncludedLabel={showIncludedLabel}
              electricLabel={electricLabel}
              setSelectedService={setSelectedService}
            />
            {cta?.href && cta?.text && (
              <div className="text-center">
                <Track eventLabel={cta.text} eventAction="link|click">
                  <a
                    data-testid="services-grid:cta"
                    className="button-outlined"
                    data-color="accent"
                    href={cta.href}
                  >
                    {cta.text}
                  </a>
                </Track>
              </div>
            )}
          </div>
        </div>
        {!!selectedService && (
          <TrackingProvider eventLabel={selectedService.displayName}>
            <TitledOverlay
              data-testid="services-grid:overlay"
              title={translate('FinanceDetails.overlay.servicesTitle')}
              close={() => {
                setSelectedService(undefined);
                setSelectedPackageService(undefined);
              }}
              minimisableTitle={false}
              colSpan={fromM ? 8 : undefined}
              navPreviousPane={
                selectedPackageService
                  ? () => {
                      setSelectedPackageService(undefined);
                    }
                  : undefined
              }
            >
              <ServiceDetailsView
                title={
                  selectedPackageService?.displayName ??
                  selectedService?.displayName
                }
                imageAltText={selectedService?.displayName}
                description={
                  selectedPackageService?.description ??
                  selectedService?.description
                }
                disclaimer={selectedService?.disclaimer}
                image={selectedPackageService?.image ?? selectedService?.image}
                items={
                  !selectedPackageService
                    ? {
                        services: selectedService.items?.map((service) => ({
                          ...service,
                          onClick: () => {
                            setSelectedPackageService(service);
                          },
                          imageAltText: service?.displayName,
                        })),
                      }
                    : {}
                }
              />
            </TitledOverlay>
          </TrackingProvider>
        )}
      </Spacing>
    </TrackingProvider>
  );
};

const Services = ({
  services,
  electricLabel,
  showIncludedLabel,
  setSelectedService,
}: Pick<ServicesGridProps, 'electricLabel'> & {
  showIncludedLabel?: boolean;
  setSelectedService: Dispatch<SetStateAction<ServiceProps | undefined>>;
  services: ServiceProps[];
}) => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { fromM } = useBreakpoints();
  const tracker = useTracker();
  const translate = useSharedComponentsTranslate();

  const renderServices = (services: ServiceProps[]) => {
    const fontVariant = !fromM ? 'micro' : 'font-16';
    return services.map((service) => (
      <ListItem
        testId="services-grid:service"
        key={service.id}
        title={service.displayName}
        titleSources={[]}
        description={service.shortDescription}
        descriptionSources={[]}
        thumbnail={
          <BaseImage
            style={{ width: 32, height: 32 }}
            data-testid="services-grid:service-icon"
            width={32}
            height={32}
            src={service.thumbnail}
            alt={service.displayName}
          />
        }
        onClick={() => {
          setSelectedService(service);
          tracker.customEvent({
            eventCategory: 'services grid',
            eventAction: 'overlay|open',
            eventLabel: `service name|${service.displayName}`,
          });
        }}
        linkVariant="chevron-title"
        descriptionVariant={fontVariant}
      />
    ));
  };

  const commonServices = renderServices(
    services.filter(({ isElectricOnly }) => !isElectricOnly),
  );
  const electricServices = renderServices(
    services.filter(({ isElectricOnly }) => isElectricOnly),
  );

  return (
    <div className="mt-32 md:mt-48">
      <GridWithLabel
        showLabel={showIncludedLabel}
        label={translate('FinanceDetails.includedServices.title')}
        services={commonServices}
      />
      {electricServices.length > 0 && (
        <GridWithLabel label={electricLabel} services={electricServices} />
      )}
    </div>
  );
};

const GridWithLabel = ({
  showLabel = true,
  label,
  services,
}: {
  showLabel?: boolean;
  label?: string;
  services: React.JSX.Element[];
}) => {
  return (
    <>
      {showLabel && label && (
        <>
          <h3
            data-testid="services-grid:category-label"
            className="font-20 font-medium"
          >
            {label}
          </h3>
          <hr className="border-t mt-16 mb-32" />
        </>
      )}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16 md:gap-x-24 md:gap-y-32 mb-32">
        {services}
      </div>
    </>
  );
};

export default ServicesGrid;
