'use client';
import { cssJoin } from '@volvo-cars/css/utils';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import { Video_EXPERIMENTAL } from '@vcc-package/media/EXPERIMENTAL';
import React from 'react';
import styles from '../finance-details.module.css';

import {
  FinanceDetailsViewHowItWorksProps,
  HowItWorksKeyPoint,
} from '../finance-details.types';

const HowItWorksKeyPointCard = ({
  title,
  image,
  shortDescription,
  width,
  videoUrl,
}: HowItWorksKeyPoint) => {
  return (
    <div style={{ width: width }} className="flex-col gap-16">
      {videoUrl ? (
        <SharedComponentsDictionariesProvider
          locale="en"
          dictionaries={{
            'VideoPlayer.controls.pause': 'pause',
            'VideoPlayer.controls.play': 'play',
          }}
        >
          <Video_EXPERIMENTAL
            videos={{
              smVideos: [{ src: videoUrl, mimeType: 'video/mp4' }],
              autoPlay: true,
              smAspectRatio: '16:9',
              loop: true,
              muted: true,
            }}
          />
        </SharedComponentsDictionariesProvider>
      ) : image ? (
        <img src={image} alt="" className="aspect-4/3 object-cover w-full" />
      ) : null}
      <h3 className="font-medium">{title}</h3>
      <p>{shortDescription}</p>
    </div>
  );
};

export const FinanceDetailsViewHowItWorks = ({
  title,
  keyPoints,
}: FinanceDetailsViewHowItWorksProps) => {
  if (!keyPoints || keyPoints?.length === 0) return null;

  return (
    <div className="flex-col gap-16">
      <h2 className="font-20 font-medium pb-16 border-b border-ornament">
        {title}
      </h2>
      <div
        className={cssJoin(
          keyPoints?.length > 2
            ? ['w-full grid pt-8 gap-x-24 gap-y-24', styles.how_it_works_grid]
            : 'flex-row gap-16',
        )}
      >
        {keyPoints?.map((item: HowItWorksKeyPoint, index: number) => (
          <HowItWorksKeyPointCard
            key={`keyPoint-${index}`}
            title={item?.title}
            image={item?.image}
            shortDescription={item?.shortDescription}
            videoUrl={item?.videoUrl}
            width="100%"
          />
        ))}
      </div>
    </div>
  );
};
