'use client';

import React from 'react';
import { type DialogProps } from '../../src/dialog/Dialog';
import { DialogMain } from '../../src/slots/dialog-main/DialogMain';
import { DialogRaw } from '../../src/components/dialog-raw/DialogRaw';
import { DialogClose } from '../../src/slots/dialog-close/DialogClose';
import styles from './MediaDialog.module.css';
import {
  ratioToClassName,
  type SupportedRatios,
} from '@vcc-package/media/utils';
import { cssMerge } from '@volvo-cars/css/utils';

export type MediaDialogProps = DialogProps & { ratio?: SupportedRatios };

export const MediaDialog: React.FC<
  React.PropsWithChildren<MediaDialogProps>
> = ({ open, onClose, ratio = '16:9', children, ...rest }) => {
  const ratioClass = ratioToClassName[ratio];

  return (
    <div className={styles['media-dialog']} data-color-mode="dark">
      <DialogRaw
        size="small"
        className={cssMerge('h-fit', styles['dialog-small'])}
        onClose={onClose}
        open={open}
        {...rest}
        render={() => (
          <>
            <DialogClose
              onClose={onClose}
              data-color-mode="dark"
              iconButtonVariant="filled"
            />
            <DialogMain>
              <div
                className={cssMerge(ratioClass, styles['aspect-scale-height'])}
              >
                <div
                  className={cssMerge(ratioClass, styles['aspect-scale-width'])}
                >
                  {children}
                </div>
              </div>
            </DialogMain>
          </>
        )}
      />
    </div>
  );
};
