'use client';
import React from 'react';
import styles from './PromotionalBanner.module.css';
import type Props from '../../content-management/content-types/promotional-banner/PromotionalBanner.props';
import { Icon } from '@volvo-cars/react-icons';
import { useDismiss } from '@volvo-cars/react-info-banner';
import { usePathname } from 'next/navigation';
import { hasConsentForFunctionalCookies } from '@vcc-package/storage/consent';
import { Track, type TrackProps } from '@volvo-cars/tracking';

interface PromotionalBannerProps extends Props {
  trackingOptions?: {
    cta: TrackProps; // Extend cta to match TrackProps type
    closeButton: TrackProps; // Extend closeButton to match TrackProps type
  };
}

export const PromotionalBanner = (props: PromotionalBannerProps) => {
  const { bannerText, cta, hideCloseButton, trackingOptions } = props;

  const pathname = usePathname();
  const { dismiss, visible } = useDismiss({
    key: pathname ?? '',
    persistToCookie: hasConsentForFunctionalCookies(),
  });

  const ctaTrackingProps: TrackProps = {
    eventLabel: 'Promotional Banner CTA',
    eventAction: 'link|click',
    ...trackingOptions?.cta,
  };

  const closeButtonTrackingProps: TrackProps = {
    eventLabel: 'Promotional Banner Close Button',
    eventAction: 'button|click',
    ...trackingOptions?.closeButton,
  };

  return (
    visible && (
      <div
        data-autoid="promotionalBanner"
        className="bg-primary gap-16 py-16 px-24 flex-row md:items-center"
        data-color-mode="dark"
      >
        <div className="container-md gap-8 md:gap-16 flex-col md:flex-row justify-center md:pl-24">
          <p className={styles['truncate-2-lines']}>{bannerText}</p>
          <Track {...ctaTrackingProps}>
            <a
              className="link-underlined font-medium min-w-fit"
              href={cta.href}
              target={cta.target}
            >
              {cta.text}
            </a>
          </Track>
        </div>
        {!hideCloseButton && (
          <Track {...closeButtonTrackingProps}>
            <button
              type="button"
              className="flex pt-4 md:pt-0"
              onClick={dismiss}
            >
              <Icon alt="Exit" color="always-white" icon="x" size={16} />
            </button>
          </Track>
        )}
      </div>
    )
  );
};
