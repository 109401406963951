'use client';
import type { ChangeEventHandler, FocusEventHandler } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { FilteredMatches } from '@vcc-package/leads-utils/api';
import { useSearchAddress } from '@vcc-package/leads-utils/hooks';
import { TextInput } from '@volvo-cars/react-forms';
import type { TextInputProps } from '@volvo-cars/react-forms';
import debounce from 'lodash/debounce';
import type { Control, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

import type { Messages } from '../types';
import { useAddressAutocompleteInputKeyEventHandler } from '../../../components';

type FieldProps = {
  name: string;
  testid?: string;
  defaultValue?: any;
  label: string;
  autoCompleteKey?: TextInputProps['autoComplete'];
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  control: Control;
  messages: Messages;
  waitOnInput?: number;
  hideRequired?: boolean;
};

export const ValidatedAddressSearch = ({
  name,
  testid,
  defaultValue,
  label,
  autoCompleteKey = 'off',
  control,
  required = false,
  minLength = 0,
  maxLength = 0, // Defaults does not work for this prop, since maxLength is a number and is 0 when "unset".
  waitOnInput = 300,
  messages,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const defaultMaxLength = 250;
  maxLength ||= defaultMaxLength;

  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<FilteredMatches[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputItem, setInputItem] = useState<null | FilteredMatches>(null);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const suggestionsWrapper = useRef<HTMLDivElement>(null);
  const searchAddress = useSearchAddress({});

  const controllerProps = useMemo<UseControllerProps>(
    () => ({
      name,
      defaultValue,
      control,
      rules: {
        required: {
          value: required,
          message: messages?.required ? messages.required(label) : '',
        },
        minLength: {
          value: minLength,
          message: messages?.length
            ? messages.length(label, minLength, maxLength)
            : '',
        },
        maxLength: {
          value: maxLength || defaultMaxLength,
          message: messages?.length
            ? messages.length(label, minLength, maxLength)
            : '',
        },
        validate: (value) => {
          if (required) {
            return true;
          }

          const invalidMsg = messages?.invalid ? messages.invalid(label) : '';
          const isPrefilled =
            defaultValue && value?.address === defaultValue?.address;

          if (inputValue !== '' && !value && !isPrefilled) {
            return invalidMsg;
          }

          return true;
        },
      },
    }),
    [
      name,
      control,
      defaultValue,
      label,
      required,
      minLength,
      maxLength,
      messages,
      inputValue,
    ],
  );

  const { field, fieldState } = useController(controllerProps);
  const isPrefilled = defaultValue && defaultValue.address;

  useEffect(() => {
    if (isPrefilled && inputItem?.address !== defaultValue.address) {
      setInputItem(defaultValue);
      setInputValue(defaultValue.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const getSuggestionsAsync = useCallback(
    async (value: string) => {
      try {
        value = value?.trim();

        if (!value) {
          setSuggestions([]);
          return { results: [], isValid: false };
        }

        setLoading(true);
        const results = await searchAddress(value);
        setSuggestions(results ?? []);
        setLoading(false);

        const isValid = !!results && !!messages?.invalid?.(label);

        return { results, isValid };
      } catch (error) {
        control.setError(name, {
          message: 'Something went wrong',
          type: 'manual',
        });
        console.error('Error in getSuggestionsAsync:', error);
        setLoading(false);
        return { results: [], isValid: false };
      }
    },
    [control, label, messages, name, searchAddress],
  );

  const selectAddress = useCallback(
    (address: string) => {
      var selectedItem = suggestions.find((s) => s.address === address);
      setShowSuggestions(false);
      setInputItem(selectedItem ?? null);

      if (selectedItem) {
        setInputValue(selectedItem?.address);
      }

      field.onChange(selectedItem);
      field.onBlur();
    },
    [suggestions, field],
  );

  useAddressAutocompleteInputKeyEventHandler({
    inputRef,
    suggestionsWrapper,
    setShowSuggestions,
    selectAddress,
  });

  const Suggestion = (predictionItem: FilteredMatches) => {
    return (
      <button
        role="option"
        aria-selected="false"
        className="tap-area text-start p-8 block w-full border-2 border-transparent selected:border-primary"
        style={{ textOverflow: 'ellipsis', outlineOffset: '-2px' }}
        key={predictionItem.id}
        type="button"
        data-testid={`${testid}_addresssuggestion_${predictionItem?.address}`}
        value={predictionItem.address}
        onMouseDown={() => selectAddress(predictionItem.address)}
      >
        {predictionItem.address}
      </button>
    );
  };

  const debouncedSearch = useMemo(
    () => debounce(getSuggestionsAsync, waitOnInput),
    [getSuggestionsAsync, waitOnInput],
  );

  const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      setInputValue(event.target.value);
      setSuggestions([]);
      await debouncedSearch(event.target.value);
      setShowSuggestions(true);
      field.onChange(null);
    },
    [field, debouncedSearch],
  );

  const _onBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    if (inputValue === '') {
      //Remove the value if input is empty
      selectAddress('');
      return;
    }

    const isPrefilled = defaultValue && inputValue === defaultValue?.address;
    if (isPrefilled) {
      setInputItem(defaultValue);
      return;
    }

    if (suggestions.length === 0) {
      field.onChange(null);
      field.onBlur();
      return;
    }

    selectAddress(inputItem?.address ?? inputValue);
  }, [inputValue, inputItem, selectAddress, defaultValue, suggestions, field]);

  return (
    <div
      role="combobox"
      aria-expanded={showSuggestions}
      aria-haspopup="listbox"
      aria-controls={`${field.name}_suggestions`}
      className="relative"
    >
      <TextInput
        id={`${field.name}_input`}
        autoComplete={autoCompleteKey || 'off'}
        spellCheck={false}
        name={name}
        data-testid={testid}
        label={hideRequired ? label : required ? `${label} *` : label}
        onChange={_onChange}
        onBlur={_onBlur}
        ref={(el) => {
          field.ref(el);
          inputRef.current = el as HTMLInputElement;
        }}
        value={inputValue}
        errorMessage={fieldState?.error?.message}
        defaultValue={isPrefilled ? undefined : defaultValue}
        aria-invalid={!!fieldState?.error?.message}
        aria-controls={`${field.name}_suggestions`}
        aria-owns={`${field.name}_suggestions`}
        aria-autocomplete="list"
      />
      {loading && (
        <progress
          style={{ top: 12, right: 16 }}
          className="absolute spinner w-32 h-32"
        />
      )}
      {showSuggestions && suggestions?.length > 0 && (
        <div
          role="listbox"
          title="Suggestions"
          tabIndex={-1}
          id={`${field.name}_suggestions`}
          data-testid="suggestions"
          className="border overflow-y-auto text-start rounded flex flex-col relative"
          style={{ maxHeight: '300px' }}
          ref={suggestionsWrapper}
        >
          {suggestions.map(Suggestion)}
        </div>
      )}
    </div>
  );
};
