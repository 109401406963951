import React, { ReactNode } from 'react';
import { Click, ExtendCSS, Link, Text, View } from 'vcc-ui';
import { SelectableCardViewProps } from './selectable-card.types';
import { snakeToKebab } from '@vcc-package/offers-utils';

export const SelectableCardView: React.FC<
  React.PropsWithChildren<SelectableCardViewProps>
> = ({
  activeHeight,
  alwaysShowChildren,
  annotation,
  children,
  contentRef,
  disabled,
  disclaimer,
  centerTitle,
  href,
  isActive,
  linkRef,
  linkText,
  linkTextSources,
  onClick,
  onSelect,
  readOnly,
  secondaryInfo,
  secondaryInfoSources,
  title,
  titleSources,
  titleTag,
  titleTagSources,
  vatLabel,
  vatLabelSources,
  name,
  value,
  testId,
}) => {
  const hasChildren = !!React.Children.toArray(children).filter(Boolean).length;

  // To avoid error button inside button
  const Wrapper = ({ children }: { children: ReactNode }) => {
    if (isActive) {
      return (
        <View
          extend={[
            containerCSS({
              isActive: !!isActive,
              disabled: !!disabled,
              readOnly: !!readOnly,
            }),
          ]}
        >
          {children}
        </View>
      );
    }
    return (
      <Click
        extend={[
          containerCSS({
            isActive: !!isActive,
            disabled: !!disabled,
            readOnly: !!readOnly,
          }),
        ]}
        onClick={onSelect}
        disabled={isActive}
        name={name}
        aria-pressed={isActive}
      >
        {children}
      </Click>
    );
  };

  return (
    <>
      <Wrapper>
        <View
          extend={contentAndTitleCSS({
            disabled: !!disabled,
            isActive: !!isActive,
            readOnly: !!readOnly,
          })}
          data-testid={`${testId}-option`}
        >
          <View
            extend={titleAndVatWrapperCSS(centerTitle)}
            data-testid={`${testId}-${snakeToKebab(value)}-option`}
            data-selected={!!isActive}
          >
            <Text
              as="h3"
              extend={titleCSS}
              variant="columbus"
              subStyle="emphasis"
              data-sources={titleSources}
              data-testid={`${testId}-${snakeToKebab(value)}-option-title`}
              aria-label={`${title}`}
            >
              {title}
              {annotation}
            </Text>
            {secondaryInfo && (
              <Text
                variant="columbus"
                data-sources={secondaryInfoSources}
                data-testid={`${testId}-${snakeToKebab(value)}-option-secondary`}
              >
                {secondaryInfo}
              </Text>
            )}
          </View>
          <View extend={titleAndVatWrapperCSS()}>
            <Text variant="bates" data-sources={titleTagSources}>
              {titleTag}
            </Text>
            <Text
              extend={vatLabelCSS}
              variant="bates"
              data-sources={vatLabelSources}
              aria-label={vatLabel}
              data-testid={`${testId}-${snakeToKebab(value)}-option-vat-label`}
            >
              {vatLabel}
            </Text>
          </View>
          <View
            ref={contentRef}
            extend={contentCSS({
              isActive: !!isActive,
              showChildren: !!alwaysShowChildren,
              activeHeight,
              hasChildren: !!hasChildren,
            })}
            data-testid={`${testId}-${snakeToKebab(value)}-option-text`}
          >
            <View extend={childrenCSS}>
              {typeof children === 'string' ? (
                <Text variant="columbus">{children}</Text>
              ) : (
                children
              )}
            </View>
          </View>
          {linkText && (href || onClick) && isActive && (
            <View
              ref={linkRef}
              extend={linkCSS({
                isActive: !!isActive,
                activeHeight: linkRef?.current?.scrollHeight,
              })}
            >
              <Link
                onClick={onClick}
                arrow="right"
                {...(href && { href })}
                data-sources={linkTextSources}
                data-testid={`${testId}-${snakeToKebab(value)}-option-link`}
                disabled={disabled}
              >
                {linkText}
              </Link>
            </View>
          )}
        </View>
      </Wrapper>
      {isActive && disclaimer ? (
        <p className="micro text-secondary">{disclaimer}</p>
      ) : null}
    </>
  );
};

const containerCSS =
  ({
    disabled,
    isActive,
    readOnly,
  }: {
    disabled: boolean;
    isActive: boolean;
    readOnly: boolean;
  }): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    boxSizing: 'border-box',
    borderColor: isActive ? color.ornament.highlight : color.ornament.border,
    borderWidth: readOnly ? 0 : 2,
    borderStyle: 'solid',
    padding: isActive ? 2 * baselineGrid - 1 : 2 * baselineGrid, //for not moving content when active
    borderRadius: baselineGrid,
    cursor: isActive || readOnly ? 'default' : 'pointer',
    height: 'min-content',
    transition: 'all .1s',
    backgroundColor: color.background.primary,
    extend: [
      {
        condition: disabled,
        style: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      },
      {
        condition: !isActive && !readOnly,
        style: {
          borderWidth: 1,
          '&:hover': {
            borderColor: color.primitive.black,
          },
          '&:active': {
            borderColor: color.ornament.border,
            boxShadow: `0px 0px 0px 2px ${color.ornament.border} inset`,
          },
        },
      },
    ],
  });

const contentAndTitleCSS = ({
  disabled,
  isActive,
  readOnly,
}: {
  disabled: boolean;
  isActive: boolean;
  readOnly: boolean;
}): ExtendCSS => ({
  extend: {
    condition: !isActive && !disabled && !readOnly,
    style: {
      '&:active': {
        transform: 'scale(0.98)',
        transition: 'all .1s',
      },
    },
  },
});

const contentCSS = ({
  isActive,
  activeHeight,
  showChildren,
  hasChildren,
}: {
  isActive: boolean;
  activeHeight: number | undefined;
  showChildren: boolean;
  hasChildren: boolean;
}): ExtendCSS => ({
  height: (isActive && hasChildren) || showChildren ? activeHeight : 0,
  minHeight: (isActive && hasChildren) || showChildren ? 'min-content' : 0,
  overflow: 'hidden',
  transition: 'height ease .3s',
});

const titleAndVatWrapperCSS =
  (centerTitle?: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    flexDirection: 'row',
    justifyContent: centerTitle ? 'center' : 'space-between',
    gap: 2 * baselineGrid,
  });

const childrenCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  paddingTop: 2 * baselineGrid,
  fromL: {
    paddingTop: 3 * baselineGrid,
  },
});

const linkCSS =
  ({
    isActive,
    activeHeight,
  }: {
    isActive: boolean;
    activeHeight: number | undefined;
  }): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => {
    const colorStyles = {
      color: color.foreground.action,
      stroke: color.foreground.action,
    };

    const selectorStyles = {
      color: color.foreground.primary,
      stroke: color.foreground.primary,
      paddingTop: 2 * baselineGrid,
      paddingBottom: 0,
      ':hover': {
        ...colorStyles,
      },
      ':focus': {
        ...colorStyles,
        ':active': {
          ...colorStyles,
        },
      },
      fromL: {
        paddingTop: 3 * baselineGrid,
      },
    };

    return {
      height: isActive ? activeHeight : 0,
      overflow: 'hidden',
      transition: 'height ease .3s',
      '& button': {
        ...selectorStyles,
      },
      '& a': {
        ...selectorStyles,
      },
    };
  };

const vatLabelCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const titleCSS: ExtendCSS = {
  flexDirection: 'row',
};
