import { type SalesModelId } from '@vcc-www/api/finance-options';
import React, { type Dispatch, type SetStateAction } from 'react';
import { useTracker } from '@volvo-cars/tracking';

import { type MergedOption } from './finopt-comparison.types';
import { GA_EVENT_CATEGORY } from './finopt-comparison.utils';
import styles from './finopt-comparison.module.css';

interface SelectContainerProps {
  salesModel: string;
  setSelectState: Dispatch<SetStateAction<SalesModelId | ''>>;
  options: MergedOption[];
  filterSelectArray: string[];
}

const SelectWrapper = ({
  salesModel,
  setSelectState,
  options,
  filterSelectArray,
}: SelectContainerProps) => {
  const tracker = useTracker();

  if (!salesModel) return null;

  return (
    <div
      className={styles['select-container']}
      data-testid="finance-options-comparison:option"
    >
      <select
        name="finance-option-select"
        style={{ height: 'auto' }}
        data-testid="finance-options-comparison:option:select"
        value={salesModel}
        onChange={(e) => {
          setSelectState(e.target.value as SalesModelId);
          tracker.customEvent({
            eventCategory: GA_EVENT_CATEGORY,
            eventAction: 'dropdown|click',
            eventLabel: `drop-down|${e.target.selectedOptions[0].text}`,
          });
        }}
      >
        {options
          .filter(({ salesModel }) => !filterSelectArray.includes(salesModel))
          .map(({ salesModel, displayName }) => (
            <option key={salesModel} value={salesModel}>
              {displayName}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectWrapper;
