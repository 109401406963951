import React from 'react';
import {
  AccordionDetails as VCCAccordionDetails,
  type AccordionDetailsProps,
} from '@volvo-cars/react-accordion';
import { useTracker } from '@volvo-cars/tracking';

type Props = AccordionDetailsProps & {
  label: string;
};

const AccordionDetails = ({
  children,
  defaultOpen,
  label,
}: React.PropsWithChildren<Props>) => {
  const tracker = useTracker();

  return (
    <VCCAccordionDetails
      data-testid="finance-options-comparison:accordion"
      defaultOpen={defaultOpen}
      onToggle={(open) => {
        tracker.customEvent({
          eventCategory: 'finance options comparison',
          eventAction: open ? 'accordion|expand' : 'accordion|collapse',
          eventLabel: label,
        });
      }}
    >
      {children}
    </VCCAccordionDetails>
  );
};

export default AccordionDetails;
