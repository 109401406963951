import AnimatedReveal from '@vcc-www/animated-reveal';
import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { PromotionCard } from './PromotionsTextImage.props';
import { Track, useTracker } from '@volvo-cars/tracking';
import { ResponsiveImage } from '@vcc-package/media';
import { Disclaimer } from '@vcc-package/text';
import styles from './PromotionsTextImage.module.css';

type Props = PromotionCard & {
  variant: 'vertical' | 'horizontal';
  className?: string;
  index: number;
  ctaVariant?: 'outline' | 'default' | 'text';
  twoRowLayout?: boolean;
  alignImage: 'left' | 'right';
  cardCount?: number;
  cards?: PromotionCard[];
};

export const Card = ({
  imageDesktop,
  imageMobile,
  title,
  cta,
  disclaimer,
  imageTablet,
  link,
  multilineText,
  text,
  variant,
  className,
  index,
  ctaVariant,
  twoRowLayout,
  alignImage,
  cardCount,
}: Props) => {
  const isCardWithImage = imageDesktop?.src && imageMobile?.src;
  const tracker = useTracker();

  return (
    <div
      className={cssMerge(
        'items-center bg-secondary',
        variant === 'horizontal' ? 'lg:flex-row lg:p-16' : 'lg:flex-col',
        className,
      )}
    >
      {isCardWithImage && (
        <div
          className={cssMerge(
            'self-center',
            variant === 'horizontal' ? styles['image-flex-50'] : '',
            twoRowLayout && isCardWithImage && styles['flex-auto'],
          )}
        >
          <ResponsiveImage
            data-testid="promotions-text-image__image"
            loading="lazy"
            className={cssMerge(
              'w-full aspect-4/3 md:aspect-16/9 object-cover',
              variant === 'horizontal' && 'lg:aspect-4/3',
            )}
            sizes={{
              md: 1,
              lg: 0.5,
            }}
            images={{
              sm: imageMobile,
              md: imageTablet,
              lg:
                variant === 'horizontal'
                  ? imageMobile
                  : imageDesktop || imageTablet,
            }}
            alt={imageDesktop?.alt ?? ''}
          />
        </div>
      )}
      <div
        className={cssMerge(
          'flex max-w-full flex-shrink flex-grow overflow-hidden',
          variant === 'horizontal' && styles['flex-100'],
        )}
      >
        <div
          className={cssMerge(
            'p-0 flex-col justify-center bg-secondary',
            alignImage === 'left'
              ? 'flex-row'
              : cardCount === 1
                ? 'flex-col lg:flex-row-reverse'
                : twoRowLayout
                  ? 'flex-row-reverse'
                  : 'flex-col',
            styles['flex-100'],
          )}
        >
          <div
            className={cssMerge(
              'w-full flex-col flex-grow flex-shrink p-64 justify-center',
              variant === 'horizontal' ? 'lg:w-1/2' : 'w-full',
            )}
          >
            <AnimatedReveal stagger={index + 2}>
              <h2
                data-testid="promotions-text-image__title"
                className="heading-2"
              >
                {title}
              </h2>
            </AnimatedReveal>
            <AnimatedReveal stagger={index + 3}>
              <p
                data-testid="promotions-text-image__text"
                className={cssMerge(
                  'text-secondary pb-32 pt-24',
                  variant === 'vertical' && 'flex-shrink flex-grow',
                )}
              >
                {multilineText || text}
              </p>
            </AnimatedReveal>
            <div>
              {cta?.href && cta?.text && (
                <AnimatedReveal stagger={index + 4}>
                  <a
                    data-testid="promotions-text-image__cta"
                    aria-label={cta.title}
                    href={cta.href}
                    onClick={() => {
                      cta.onClick;
                      tracker.customEvent({
                        eventAction: 'button|click',
                        eventLabel: `${cta.text}|${cta.href}`,
                        eventCategory: 'promotion text image button',
                      });
                    }}
                    data-color="accent"
                    className={cssMerge(
                      ctaVariant === 'outline' && 'button-outlined',
                      ctaVariant === 'default' && 'button-filled',
                      ctaVariant === 'text' && 'button-text',
                      !ctaVariant && 'button-outlined',
                    )}
                  >
                    {cta.text}
                  </a>
                </AnimatedReveal>
              )}
              {link?.href && link?.text && (
                <AnimatedReveal stagger={index + 5}>
                  <Track
                    eventLabel={`${link.text} | ${link.href}`}
                    eventAction="link|click"
                  >
                    <a
                      data-testid="promotions-text-image__link"
                      aria-label={link.title}
                      href={link.href}
                      target={link.target}
                      className="text-accent-blue"
                    >
                      {link.text}
                    </a>
                  </Track>
                </AnimatedReveal>
              )}
            </div>
          </div>
        </div>
        {!!disclaimer && (
          <Disclaimer
            extend={({ theme: { baselineGrid } }) => ({
              marginTop: 4 * baselineGrid,
              fromL: { display: 'none' },
            })}
            text={disclaimer}
          />
        )}
      </div>
    </div>
  );
};
