'use client';

import React, { type CSSProperties, useState } from 'react';
import { type LinkField } from '@vcc-www/content-management-jss-client';
import { useKeyPress } from '@volvo-cars/react-utils';
import { Track } from '@volvo-cars/tracking';
import { Icon } from '@volvo-cars/react-icons';
import { DisclaimerDialog } from '../disclaimer-dialog/DisclaimerDialog';

export type PopUpDisclaimerContent = {
  title?: string;
  titleSource?: string;
  description: string;
  descriptionSource?: string;
  descriptionTitle?: string;
  descriptionTitleSource?: string;
  link?: LinkField;
  linkSources?: Array<string>;
};

export type PopUpDisclaimerProps = PopUpDisclaimerContent & {
  iconVerticalAlign?: CSSProperties['verticalAlign'];
  iconStyle?: string;
  trackLabel?: string;
  addMarginInlineStart?: boolean;
};

export const PopUpDisclaimer: React.FC<
  PopUpDisclaimerProps & { children: (icon: JSX.Element) => React.ReactNode }
> = ({
  title,
  titleSource,
  description,
  descriptionSource,
  descriptionTitle,
  descriptionTitleSource,
  link,
  linkSources = ['', ''],
  iconVerticalAlign = 'baseline',
  iconStyle,
  trackLabel,
  addMarginInlineStart,
  children,
}) => {
  const [openOverlay, showDisclaimerOverlay] = useState(false);
  useKeyPress('Escape', () => showDisclaimerOverlay(false));
  const disclaimerIcon = (
    <Track
      eventLabel={trackLabel || `open PopUpDisclaimer | ${title}`}
      eventAction="image|click"
    >
      <button
        onClick={() => showDisclaimerOverlay(true)}
        type="button"
        className={`${addMarginInlineStart ? 'ml-4' : ''} ${
          iconStyle ? iconStyle : ''
        }`}
        aria-label={`Click ${title} pop-up disclaimer info icon for more information`}
        style={{ verticalAlign: iconVerticalAlign }}
      >
        <Icon icon="info-circled" size={16} />
      </button>
    </Track>
  );

  return (
    <>
      {children(disclaimerIcon)}
      <DisclaimerDialog
        title={title}
        titleSource={titleSource}
        description={description}
        descriptionSource={descriptionSource}
        descriptionTitle={descriptionTitle}
        descriptionTitleSource={descriptionTitleSource}
        open={openOverlay}
        onClose={() => showDisclaimerOverlay(false)}
        link={link}
        linkSources={linkSources}
      />
    </>
  );
};
