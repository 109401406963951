import React, {
  createRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import VideoOrImage from '@vcc-www/video-or-image';
import styles from './FeaturesOverview.module.css';
import { ImageField, LinkField } from '@vcc-www/content-management-jss-client';
import { FileEntry } from '@volvo-cars/content-management-client';
import { useInView, useBreakpoints } from '@vcc-www/hooks';
import {
  useTab,
  useTabList,
  useTabPanel,
  TabGroupState,
  useAnimatedDetails,
} from '@volvo-cars/react-headless';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import { PopUpDisclaimer, PopUpDisclaimerProps } from '@vcc-package/overlays';

export interface FeaturesOverviewProps {
  label?: string;
  title: string;
  body?: string;
  image?: ImageField;
  imageUltrawide?: ImageField;
  video?: FileEntry;
  videoUltrawide?: FileEntry;
  items: Array<FeaturesOverviewItemProps>;
  link?: LinkField;
  animationDuration?: number;
  enableAutoplay?: boolean;
  loopVideos?: boolean;
  popUpDisclaimer?: PopUpDisclaimerProps;
  imageDensity?: number;
  leftAlignIntroText?: boolean;
  shouldAutoChangeItems?: boolean;
}

interface FeaturesOverviewItemProps {
  title: string;
  description: string;
  image?: ImageField;
  imageUltrawide?: ImageField;
  video?: FileEntry;
  videoUltrawide?: FileEntry;
  eventLabel?: string;
}

const DEFAULT_ANIMATION_DURATION = 5000;

interface TabButtonProps {
  title: string;
  description: string;
  index: number;
  selectedItemIndex: number;
  tabGroupState: TabGroupState;
  hasInteractedCb: (index: number) => void;
  eventLabel?: string;
}
interface AccordionTabProps {
  title: string;
  description: string;
  index: number;
  selectedItemIndex: number;
}

const AccordionTab: React.FC<AccordionTabProps> = ({
  title,
  description,
  selectedItemIndex,
  index,
}) => {
  const detailsProps = useAnimatedDetails({
    open: selectedItemIndex === index,
  });

  return (
    <details
      className={`mb-24 pl-24 text-start border-l-2 border-ornament ${
        selectedItemIndex === index ? 'border-always-black' : ''
      }`}
      {...detailsProps}
    >
      <summary className="font-20">
        <strong>{title}</strong>
      </summary>
      <p className="text-secondary mt-8">{description}</p>
    </details>
  );
};

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  (props, ref) => {
    // eslint-disable-next-line vcc-www/use-breakpoints
    const { untilL } = useBreakpoints();
    const {
      title,
      index,
      tabGroupState,
      hasInteractedCb,
      description,
      selectedItemIndex,
      eventLabel,
    } = props;

    const { tabProps } = useTab({
      state: tabGroupState,
      index,
    });

    const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.isTrusted) {
        hasInteractedCb(index);
      }
    };

    // Rendering react-accordion for mobile and design-system tabs for large devices until we replace it with SteppedAccordion
    return (
      <Track
        eventLabel={eventLabel || title}
        eventAction="toggle|click"
        key={tabProps.key}
      >
        {untilL ? (
          <button
            data-testid="features-overview:item"
            ref={ref}
            type="button"
            onClick={handleTabClick}
          >
            <AccordionTab
              title={title}
              description={description}
              selectedItemIndex={selectedItemIndex}
              index={index}
            />
          </button>
        ) : (
          <button
            {...tabProps}
            key={tabProps.key}
            ref={ref}
            className={`${styles['tab-button']} px-8 font-20 font-medium tap-area rounded-t-sm flex-grow min-h-64 border-b-2 border-ornament selected:border-primary transition-colors`}
            type="button"
            data-testid="features-overview:item"
            onClick={handleTabClick}
          >
            {title}
          </button>
        )}
      </Track>
    );
  },
);

TabButton.displayName = 'TabButton';

const FeaturesOverview: React.FC<FeaturesOverviewProps> = ({
  label,
  title,
  popUpDisclaimer,
  body,
  image,
  imageUltrawide,
  video,
  videoUltrawide,
  items,
  link,
  animationDuration,
  enableAutoplay,
  loopVideos,
  imageDensity,
  leftAlignIntroText = false,
  shouldAutoChangeItems = true,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const tabsRef = useRef<Array<HTMLButtonElement>>(
    Array(items.length).fill(createRef()),
  );
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL } = useBreakpoints();
  const { tabListProps, tabGroupState } = useTabList();
  const { tabPanelProps } = useTabPanel({
    state: tabGroupState,
    index: selectedItemIndex,
  });
  const [ref, isInView] = useInView<HTMLDivElement>({
    threshold: 0.2,
  });

  const timeoutTime =
    typeof animationDuration === 'number'
      ? animationDuration * 1000
      : DEFAULT_ANIMATION_DURATION;

  useEffect(() => {
    const nextIndex = (selectedItemIndex + 1) % items.length;
    const shouldAnimate =
      shouldAutoChangeItems && !hasInteracted && isInView && timeoutTime > 0;

    if (shouldAnimate) {
      const timeoutId = setTimeout(() => {
        setSelectedItemIndex(nextIndex);
        if (typeof tabsRef.current[nextIndex]?.click === 'function')
          tabsRef.current[nextIndex]?.click();
      }, timeoutTime);

      return () => clearTimeout(timeoutId);
    }
  }, [
    items,
    hasInteracted,
    timeoutTime,
    isInView,
    selectedItemIndex,
    tabsRef,
    shouldAutoChangeItems,
  ]);

  const handleButtonClick = (index: number) => {
    setHasInteracted(true);
    setSelectedItemIndex(index);
  };

  if (items.length > 4) return null;

  return (
    <TrackingProvider>
      <div ref={ref}>
        <div className="container-lg mx-auto">
          <div
            className={
              leftAlignIntroText
                ? `container-sm md:text-start mx-0 w-full`
                : `container-sm m-auto md:text-center`
            }
          >
            {label && <p className="text-secondary mb-16">{label}</p>}
            {popUpDisclaimer && popUpDisclaimer.description ? (
              <PopUpDisclaimer
                {...popUpDisclaimer}
                trackLabel={`open PopUpDisclaimer | ${title}`}
              >
                {(icon) => (
                  <h2
                    className={`heading-2 mb-16 md:mb-24 ${
                      leftAlignIntroText ? '' : 'text-start md:text-center'
                    }`}
                  >
                    {title} {icon}
                  </h2>
                )}
              </PopUpDisclaimer>
            ) : (
              <h2
                className={`heading-2 mb-16 md:mb-24 ${
                  leftAlignIntroText ? '' : 'text-start md:text-center'
                }`}
              >
                {title}
              </h2>
            )}
            {body && <p className="text-secondary mb-32 md:mb-64">{body}</p>}
          </div>
        </div>
        <VideoOrImage
          // Needed to make video component re-render when source changes.
          key={items[selectedItemIndex]?.title}
          altText={
            items[selectedItemIndex]?.image?.alt ||
            items[selectedItemIndex]?.imageUltrawide?.alt ||
            image?.alt ||
            imageUltrawide?.alt ||
            ''
          }
          aspectRatio={{ default: [4, 3], fromM: [16, 9] }}
          imageSources={{
            default: items[selectedItemIndex]?.image?.src || image?.src || '',
            fromM:
              items[selectedItemIndex]?.imageUltrawide?.src ||
              imageUltrawide?.src,
          }}
          videoSourcesMp4={{
            default: items[selectedItemIndex]?.video?.src || video?.src || '',
            fromM:
              items[selectedItemIndex]?.videoUltrawide?.src ||
              videoUltrawide?.src,
          }}
          shouldAutoplay={!!enableAutoplay}
          loop={!!loopVideos}
          imageDensity={imageDensity}
          sizes={{ default: '100vw', fromL: '1232px' }}
        />
        <div className="container-lg mx-auto">
          <div
            {...tabListProps}
            aria-label="tab navigation"
            className="flex-col my-32 lg:flex-row"
            role="button"
            tabIndex={0}
          >
            {items.map(({ title, description, eventLabel }, index) => (
              <TabButton
                key={index}
                ref={(ref: any) => {
                  tabsRef.current[index] = ref as HTMLButtonElement;
                }}
                hasInteractedCb={handleButtonClick}
                title={title}
                index={index}
                tabGroupState={tabGroupState}
                description={description}
                selectedItemIndex={selectedItemIndex}
                eventLabel={eventLabel}
              />
            ))}
          </div>
          {untilL ? null : (
            <p
              {...tabPanelProps}
              key={tabPanelProps.key}
              className="container-sm text-secondary mt-16 md:text-center md:mx-auto"
            >
              {items[selectedItemIndex]?.description || ''}
            </p>
          )}
          {link?.href && link?.text && (
            <div className="mt-32 md:mx-auto w-fit">
              <Track
                eventLabel={`${link.text} | ${link.href}`}
                eventAction="link|click"
              >
                <a href={link.href} className="button-text">
                  {link.text}
                </a>
              </Track>
            </div>
          )}
        </div>
      </div>
    </TrackingProvider>
  );
};

export default FeaturesOverview;
