'use client';
import React, { useState } from 'react';
import { cssJoin } from '@volvo-cars/css/utils';
import { BaseImageProps, ResponsiveImageProps } from './types';
import styles from './progressive-image.module.css';

const getImgSrcFromProps = <T extends ResponsiveImageProps | BaseImageProps>(
  props: T,
): string => {
  return (
    (props as BaseImageProps).src ||
    (props as ResponsiveImageProps).images?.md?.src ||
    (props as ResponsiveImageProps).images?.lg?.src ||
    (props as ResponsiveImageProps).images?.sm?.src ||
    ''
  );
};

const getThumbnailUrl = (src: string): string => {
  if (!src) return '';
  const url = new URL(src);
  url.searchParams.set('w', '10');
  url.searchParams.set('imdensity', '1');
  return url.toString();
};

export function withProgressiveImage<
  T extends ResponsiveImageProps | BaseImageProps,
>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
  return function ProgressiveImage(props) {
    const [loaded, setLoaded] = useState(false);
    const src = getImgSrcFromProps(props as T);
    const thumbnailUri = getThumbnailUrl(src);
    const onLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setLoaded(true);
      props.onLoad?.(event);
    };

    return (
      <div
        className={cssJoin([
          'relative',
          styles['thumbnail'],
          loaded && styles['loaded'],
        ])}
        style={{
          backgroundImage: thumbnailUri ? `url(${thumbnailUri})` : 'none',
        }}
      >
        <WrappedComponent {...({ ...props, onLoad } as T)} />
      </div>
    );
  };
}
