import React, { useState } from 'react';
import { useTracker } from '@volvo-cars/tracking';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useFinanceOptionToFinanceDetails } from '@vcc-www/api/finance-options/utils';

import { type GridProps, type MergedOption } from './finopt-comparison.types';
import {
  GA_EVENT_CATEGORY,
  salesModelToLabel,
} from './finopt-comparison.utils';
import styles from './finopt-comparison.module.css';
import { Dialog, DialogMain } from '@vcc-package/overlays';
import { FinanceDetailsView } from '@vcc-package/finance-details/src/views/finance-details.view';
import { FinanceDetailsProps } from '@vcc-package/finance-details/src/finance-details.types';

const LinkContainer = ({
  option,
  openOverlay,
}: {
  option: MergedOption;
  openOverlay: (financeDetailsData: FinanceDetailsProps) => void;
}) => {
  const tracker = useTracker();
  const financeDetailsData = useFinanceOptionToFinanceDetails(option);

  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};
  const gaEventLabel = `${option?.financeOptionLinkText}|${
    salesModelToLabel[option.salesModel]
  }`;

  if (option.financeOptionLinkUrl) {
    linkProps['href'] = option.financeOptionLinkUrl.href;
    linkProps['onClick'] = () => {
      tracker.customEvent({
        eventCategory: GA_EVENT_CATEGORY,
        eventAction: 'link|click',
        eventLabel: gaEventLabel,
      });
    };
  } else {
    linkProps['onClick'] = () => {
      openOverlay(financeDetailsData);
      tracker.customEvent({
        eventCategory: GA_EVENT_CATEGORY,
        eventAction: 'overlay|click',
        eventLabel: gaEventLabel,
      });
    };
  }

  const cta = React.createElement(
    option.financeOptionLinkUrl ? 'a' : 'button',
    { className: 'button-text', ['data-color']: 'accent', ...linkProps },
    option?.financeOptionLinkText,
  );

  return <div className="flex mt-32">{cta}</div>;
};

const LinksGrid = ({
  firstSalesModel,
  secondSalesModel,
  thirdSalesModel,
  options,
}: GridProps) => {
  const firstFinanceOption = options.find(
    ({ salesModel }) => salesModel === firstSalesModel,
  ) as MergedOption;
  const secondFinanceOption = options.find(
    ({ salesModel }) => salesModel === secondSalesModel,
  ) as MergedOption;
  const thirdFinanceOption = options.find(
    ({ salesModel }) => salesModel === thirdSalesModel,
  ) as MergedOption;

  const translate = useSharedComponentsTranslate();
  const [overlayIsOpen, setOverlayIsOpen] = useState(false);
  const [financeDetailsData, setFinanceDetailsData] =
    useState<FinanceDetailsProps>();

  const openOverlay = (financeDetailsData: FinanceDetailsProps) => {
    setFinanceDetailsData(financeDetailsData);
    setOverlayIsOpen(true);
  };

  return (
    <>
      <div
        className={styles['grid-container']}
        data-testid="finance-options-comparison:links"
      >
        <LinkContainer option={firstFinanceOption} openOverlay={openOverlay} />
        <LinkContainer option={secondFinanceOption} openOverlay={openOverlay} />
        {thirdSalesModel && (
          <LinkContainer
            option={thirdFinanceOption}
            openOverlay={openOverlay}
          />
        )}
      </div>
      <Dialog
        open={overlayIsOpen}
        onClose={() => {
          setOverlayIsOpen(false);
        }}
        title={translate('FinanceDetails.overlay.financialOptionTitle')}
      >
        <DialogMain>
          <FinanceDetailsView
            {...(financeDetailsData as FinanceDetailsProps)}
          />
        </DialogMain>
      </Dialog>
    </>
  );
};

export default LinksGrid;
