'use client';
import React, { useState, useCallback } from 'react';
import { Flex, Block, ThemePicker } from 'vcc-ui';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { Icon } from '@vcc-www/video/Video.icons';
import { Button, Click } from '@vcc-www/buttons';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import VideoProps, { YoutubeVideo as YoutubeVideoProps } from './VideoProps';
import OptimizedVideo from './OptimizedVideo';
import { getAspectRatio } from './Video.utils';
import {
  buttonCSS,
  videoContainerCSS,
  videoFrameCSS,
  youtubeVideoContainerCSS,
} from './sharedCss';
import {
  DEFAULT_TRACK_LABEL,
  DEFAULT_GA3_TRACK_LABEL,
} from './OptimizedVideo/hooks';

type Params = Omit<VideoProps, 'content'>;

const isDarkTheme = (key: VideoProps['background'] = 'dark') =>
  ['black', 'dark'].includes(key);

const YouTubeVideo: React.FC<{
  onPlay?: () => void;
  content: YoutubeVideoProps;
  params: Params;
  trackLabel?: string;
  ga3TrackLabel?: string;
  trackEventAction?: string;
}> = ({
  content,
  params,
  onPlay,
  trackEventAction,
  trackLabel = DEFAULT_TRACK_LABEL,
  ga3TrackLabel = DEFAULT_GA3_TRACK_LABEL,
}) => {
  const { languageCode: marketLanguageCode, siteSlug } = useCurrentMarketSite();
  const languageCode = siteSlug === 'tw' ? 'zh-Hant' : marketLanguageCode; // INC3915703, Tawainese market Youtube videos shows Simplified Chinese instead of Traditional Chinese.

  const {
    youtubeURL,
    videoDesktopMp4,
    videoMobileMp4,
    videoDesktopWebM,
    videoMobileWebM,
    desktopPoster,
    mobilePoster,
    useLoopVideoAsPosterForYouTube,
    subtitleTrack,
    ctaLabel,
    mobileAspectRatio,
    desktopAspectRatio,
    youtubeIframeTitle,
    videoDescription,
  } = content;
  const {
    largePlayIcon,
    centeredPlayIcon,
    background = 'dark',
    autoplay,
  } = params;
  const aspectRatio: AspectRatio = getAspectRatio(
    mobileAspectRatio,
    desktopAspectRatio,
  );
  const hasLoopVideo = !!(
    videoDesktopMp4 ||
    videoDesktopWebM ||
    videoMobileMp4 ||
    videoMobileWebM
  );

  const [videoIsStarted, setVideoIsStarted] = useState(
    (!desktopPoster && !hasLoopVideo) ||
      (!useLoopVideoAsPosterForYouTube && hasLoopVideo),
  );

  const startVideo = useCallback(() => {
    // youtube js api is flimsey, this is to at least
    // get the initial state change so that it's possible
    // to hide elements which are above it etc.
    onPlay?.();
    setVideoIsStarted(true);
  }, [onPlay]);

  const hasCta = !!ctaLabel;
  const iconId = videoDesktopMp4?.src || 'video' + videoDesktopWebM?.src;

  return (
    <ThemePicker variant={isDarkTheme(background) ? 'dark' : 'light'}>
      {videoIsStarted ? (
        <Flex
          extend={videoContainerCSS(aspectRatio, !!mobilePoster?.src)}
          data-testid="video:youtube"
        >
          <iframe
            data-testid="video:iframe"
            style={videoFrameCSS}
            title={youtubeIframeTitle}
            frameBorder={0}
            allowFullScreen
            src={`${youtubeURL}?color=white&cc_load_policy=1&rel=0&autoplay=${
              autoplay ? 1 : 0
            }&cc_lang_pref=${languageCode}&hl=${languageCode}`}
            allow="autoplay"
          />
        </Flex>
      ) : (
        <Flex
          data-testid="video:youtube"
          extend={[
            youtubeVideoContainerCSS,
            {
              extend: {
                condition:
                  aspectRatio.default === 'responsive' &&
                  aspectRatio.untilM === 'responsive',
                style: {
                  height: '100%',
                },
              },
            },
          ]}
        >
          <YouTubeVideoContent
            startVideo={startVideo}
            useLoopVideoAsPosterForYouTube={useLoopVideoAsPosterForYouTube}
            hasLoopVideo={hasLoopVideo}
            aspectRatio={aspectRatio}
            mobilePoster={mobilePoster}
            videoMobileWebM={videoMobileWebM}
            desktopPoster={desktopPoster}
            videoMobileMp4={videoMobileMp4}
            videoDesktopMp4={videoDesktopMp4}
            subtitleTrack={subtitleTrack}
            videoDesktopWebM={videoDesktopWebM}
            hasCta={hasCta}
            largePlayIcon={largePlayIcon}
            iconId={iconId}
            centeredPlayIcon={centeredPlayIcon}
            videoDescription={videoDescription}
            trackLabel={trackLabel}
            ga3TrackLabel={ga3TrackLabel}
          />
          {hasCta && (
            <Block extend={buttonCSS(largePlayIcon, centeredPlayIcon, hasCta)}>
              <Button
                onClick={startVideo}
                intent="secondary"
                // @ts-ignore Check if this is correct value to track for ga4
                trackEventAction={
                  trackEventAction ? trackEventAction : 'media|play'
                }
                trackEventLabel={trackLabel}
              >
                {ctaLabel}
              </Button>
            </Block>
          )}
        </Flex>
      )}
    </ThemePicker>
  );
};

interface YouTubeVideoContentProps {
  startVideo(): void;
  useLoopVideoAsPosterForYouTube: YoutubeVideoProps['useLoopVideoAsPosterForYouTube'];
  hasLoopVideo: boolean;
  aspectRatio: ReturnType<typeof getAspectRatio>;
  mobilePoster: YoutubeVideoProps['mobilePoster'];
  videoMobileWebM: YoutubeVideoProps['videoMobileWebM'];
  desktopPoster: YoutubeVideoProps['desktopPoster'];
  videoMobileMp4: YoutubeVideoProps['videoMobileMp4'];
  videoDesktopMp4: YoutubeVideoProps['videoDesktopMp4'];
  subtitleTrack: YoutubeVideoProps['subtitleTrack'];
  videoDesktopWebM: YoutubeVideoProps['videoDesktopWebM'];
  hasCta: boolean;
  largePlayIcon?: boolean;
  iconId: string;
  centeredPlayIcon?: boolean;
  videoDescription?: string | undefined;
  trackLabel?: string;
  ga3TrackLabel?: string;
  trackEventAction?: string;
}

const YouTubeVideoContent: React.FC<
  React.PropsWithChildren<YouTubeVideoContentProps>
> = ({
  startVideo,
  useLoopVideoAsPosterForYouTube,
  hasLoopVideo,
  aspectRatio,
  mobilePoster,
  videoMobileWebM,
  desktopPoster,
  videoMobileMp4,
  videoDesktopMp4,
  subtitleTrack,
  videoDesktopWebM,
  hasCta,
  largePlayIcon,
  iconId,
  centeredPlayIcon,
  videoDescription,
  trackLabel = DEFAULT_TRACK_LABEL,
  trackEventAction,
}) => {
  const posterProps = mobilePoster?.src
    ? {
        aspectRatio,
        objectFit: 'cover' as const,
        extend: {
          extend: [
            {
              condition: aspectRatio.default === 'responsive',
              style: { height: '100%' },
            },
            {
              condition: aspectRatio.untilM === 'responsive',
              style: { untilM: { height: '100%' } },
            },
          ],
        },
      }
    : {};

  return (
    <Click
      data-testid="video:playvideo"
      onClick={startVideo}
      // @ts-ignore Check if this is correct value to track for ga4
      trackEventAction={trackEventAction ? trackEventAction : `media|play`}
      trackEventLabel={trackLabel}
      extend={{
        extend: [
          {
            condition: aspectRatio.untilM === 'responsive',
            style: {
              untilM: {
                height: '100%',
              },
            },
          },
          {
            condition: aspectRatio.default === 'responsive',
            style: {
              height: '100%',
            },
          },
        ],
      }}
    >
      {useLoopVideoAsPosterForYouTube && hasLoopVideo ? (
        <OptimizedVideo
          aspectRatio={aspectRatio}
          poster={{
            default: mobilePoster?.src || desktopPoster?.src || '',
            fromM: desktopPoster?.src,
          }}
          srcMp4={{
            default: videoMobileMp4?.src || videoDesktopMp4?.src || '',
            fromM: videoDesktopMp4?.src,
          }}
          srcWebM={{
            default: videoMobileWebM?.src || videoDesktopWebM?.src || '',
            fromM: videoDesktopWebM?.src,
          }}
          subtitleTrack={subtitleTrack?.src}
          loop
          controls="disabled"
          videoDescription={videoDescription}
        />
      ) : (
        <Image_DEPRECATED
          loading="native-lazy"
          sources={{
            default: mobilePoster?.src || desktopPoster?.src || '',
            fromM: desktopPoster?.src,
          }}
          alt={mobilePoster?.alt || desktopPoster?.alt || ''}
          {...posterProps}
        />
      )}
      {!hasCta && (
        <Block extend={buttonCSS(largePlayIcon, centeredPlayIcon, hasCta)}>
          <Icon type="play" id={iconId} />
        </Block>
      )}
    </Click>
  );
};

export default YouTubeVideo;
