'use client';
import React from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { useTracker } from '@volvo-cars/tracking';

import { Spacing } from '../../layout/Spacing';
import type { FileEntry } from '@volvo-cars/content-management-client';

export interface FaqDocumentsProps {
  type: 'faqDocuments';
  title: string;
  body?: string;
  documents: DocumentProps[];
}

interface DocumentProps {
  url?: FileEntry;
  link?: string;
  text: string;
}

export const FaqDocuments: React.FC<
  React.PropsWithChildren<FaqDocumentsProps>
> = ({ title, body, documents }) => (
  <Spacing>
    <div data-testid="faq-documents" className="container-md">
      <div className="mb-s">
        <h2
          data-testid="faq-documents__title"
          className="heading-2 lg:text-center"
        >
          {title}
        </h2>
        {body && (
          <p
            data-testid="faq-documents__body"
            className="lg:text-center text-secondary mt-16"
          >
            {body}
          </p>
        )}
      </div>
      <div className="lg:grid-cols-2 gap-x-24">
        {documents.map((document, i) => (
          <Document {...document} key={i} />
        ))}
      </div>
    </div>
  </Spacing>
);

const Document: React.FC<React.PropsWithChildren<DocumentProps>> = ({
  url,
  link,
  text,
}) => {
  const tracker = useTracker();
  return (
    <a
      data-testid="faq-documents__document"
      className="border-ornament flex-row items-center border-b py-24"
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={link || url?.src}
      onClick={() =>
        tracker.customEvent({
          eventAction: 'link|click',
          eventLabel: text.toLowerCase().replace(/ /g, '_'),
          eventCategory: 'faq documents',
        })
      }
    >
      <Icon icon={link ? 'external-link' : 'download'} size={24} />
      <p
        data-testid="faq-documents__document__title"
        className="text-secondary pl-16"
      >
        {text}
      </p>
    </a>
  );
};
