'use client';

import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';

export type DialogCloseProps = {
  onClose: (event: any) => void;
  hideCloseButton?: boolean;
  iconButtonVariant?: React.ComponentProps<typeof IconButton>['variant'];
};

export const DialogClose = ({
  onClose,
  hideCloseButton,
  iconButtonVariant,
  ...rest
}: DialogCloseProps) => {
  const translate = useSharedComponentsTranslate();

  return (
    <div slot="close" {...rest}>
      {!hideCloseButton ? (
        <IconButton
          icon="x"
          aria-label={translate('ReactOverlay.labels.close')}
          onClick={onClose}
          variant={iconButtonVariant || 'clear'}
          data-bleed="true"
          className="fade-in"
        />
      ) : null}
    </div>
  );
};
