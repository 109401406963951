'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import {
  FlexFormTestID,
  NonApiFieldName,
} from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';

import { TextAreaField } from '../../flexible-forms/components';

export const NotesField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.NOTES,
  );

  return (
    <TextAreaField
      hideRequired={hideRequired}
      key="description"
      name={NonApiFieldName.Description}
      testid={FlexFormTestID.Notes}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.ADDITIONAL_NOTES_FIELD_LABEL)
      }
      description={
        customTranslationKeys?.description
          ? translate(customTranslationKeys.description)
          : undefined
      }
      control={control}
      messages={messages}
      {...validation.notes}
    />
  );
};
