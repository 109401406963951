import React, { ComponentPropsWithRef, forwardRef } from 'react';
import {
  Button as VCCButton,
  ButtonUniqueProps,
  ComponentWithHref,
} from 'vcc-ui';
import { TrackingProps, Track } from '@volvo-cars/tracking';

export const Button = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  ButtonUniqueProps & TrackingProps
>(function Click(props, ref) {
  const {
    trackEventLabel,
    trackData,
    trackEvent,
    trackEventAction,
    ...restProps
  } = props;

  return (
    <Track
      eventAction={trackEventAction}
      eventLabel={trackEventLabel || ''}
      customData={trackData}
      event={trackEvent}
    >
      <VCCButton ref={ref} {...restProps} />
    </Track>
  );
}) as ComponentWithHref<TrackingProps & ButtonUniqueProps, string | undefined>;

export type ButtonProps = ComponentPropsWithRef<typeof Button>;
