'use client';

import React, {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { isElement, isFragment } from 'react-is';
import { Tracker, type TrackerOptions, type TrackEvent } from './Tracker';
import { type TrackingData } from './types';

export const TrackingContext = createContext<{
  /**
   * True if the current Provider or any parent Providers got `enableReactTracking=true`.
   */
  isReactTrackingEnabledForTree?: boolean;
  logging?: boolean;
  forceLowerCase?: boolean;
  trackingData?: TrackingData;
  deferNonInteraction?: boolean;
  hasPageTrackingProvider?: boolean;
}>({});

export interface TrackingProviderProps
  extends TrackingData,
    Omit<TrackerOptions, 'disabled'> {
  /**
   * Push the tracking props to the dataLayer immediately on page load.
   * @deprecated Use `PageTrackingProvider` instead
   */
  trackPageLoad?: boolean;

  /**
   * Use React Context instead of `data-` attributes to maintain tracking data.
   *
   * @default true
   */
  enableReactTracking?: boolean;

  /**
   * Custom tracking event to pass through to Tracker.
   */
  event?: TrackEvent;
}

/**
 * Merges any existing Tracking Context with the given props.
 */
export const TrackingProvider: React.FC<TrackingProviderProps> = ({
  children,
  trackPageLoad,
  logging,
  forceLowerCase,
  deferNonInteraction,
  enableReactTracking = true,
  ...props
}) => {
  const previousContext = useContext(TrackingContext);
  const isReactTrackingEnabledForTree =
    enableReactTracking || previousContext.isReactTrackingEnabledForTree;
  const trackingData = {
    ...previousContext.trackingData,
    ...props,
  };

  if (
    process.env.NODE_ENV !== 'production' &&
    !enableReactTracking &&
    previousContext.isReactTrackingEnabledForTree
  ) {
    throw new Error(
      `Don't disable React Tracking after it has been enabled in an outer provider`,
    );
  }
  useDevErrorOnChange(enableReactTracking, 'enableReactTracking');
  useDevErrorOnChange(trackPageLoad, 'trackPageLoad');

  const shouldLog =
    typeof logging === 'boolean' ? logging : previousContext.logging;

  useEffect(() => {
    if (isReactTrackingEnabledForTree && trackPageLoad) {
      const tracker = new Tracker(null, {
        logging: shouldLog,
        forceLowerCase,
      });
      tracker.pageView(trackingData);
    }
    // This should only happen once per mount of component
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const trackingChildren = isReactTrackingEnabledForTree ? (
    children
  ) : (
    <DomTrackingWrapper context={props} pageload={trackPageLoad}>
      {children}
    </DomTrackingWrapper>
  );

  return (
    <TrackingContext.Provider
      value={{
        trackingData,
        isReactTrackingEnabledForTree,
        deferNonInteraction:
          typeof deferNonInteraction === 'boolean'
            ? deferNonInteraction
            : previousContext.deferNonInteraction,
        logging: shouldLog,
        forceLowerCase:
          typeof forceLowerCase === 'boolean'
            ? forceLowerCase
            : previousContext.forceLowerCase,
      }}
    >
      {trackingChildren}
    </TrackingContext.Provider>
  );
};

export const DomTrackingWrapper: React.FC<{
  children: React.ReactNode;
  context: unknown;
  pageload?: boolean;
}> = ({ context, children, pageload }) => {
  const trackWrapperProps = {
    // When we're not using React based tracking, we store the context in data
    // attributes that event handlers can traverse to get the tracking context.
    'data-track-context': JSON.stringify(context),
    ...(pageload && {
      'data-track-pageload': 'true',
    }),
  };

  // We HOPE that immediate `children` is already a DOM node,
  // reuse it and add data attributes, otherwise create
  // a wrapper div.
  if (isElement(children) && !isFragment(children)) {
    return cloneElement(children, trackWrapperProps);
  } else {
    return (
      <div style={{ display: 'contents' }} {...trackWrapperProps}>
        {children}
      </div>
    );
  }
};

export function useDevErrorOnChange(propValue: unknown, propName: string) {
  const prevPropValue = useRef(propValue);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (propValue !== prevPropValue.current) {
        throw new Error(
          `Do not change the value of ${propName} once rendered.`,
        );
      }
      prevPropValue.current = propValue;
    }
  }, [propValue, propName]);
}
