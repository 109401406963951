'use client';
import React, { forwardRef } from 'react';
import { Link as VCCUILink, ExtendCSS, Block, CurrentTheme } from 'vcc-ui';
import { WithTrackerProps, Track } from '@volvo-cars/tracking';

export type LinkProps = {
  /**
   * A less prominent link style (to be incorporated into VCC-UI)
   */
  arrowCentered?: boolean;
  subtle?: boolean;
  lowercaseText?: boolean;
  colorsInverted?: boolean;
  extend?: ExtendCSS;
  titleIsHovered?: boolean;
} & WithTrackerProps<typeof VCCUILink>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    arrow,
    arrowCentered,
    colorsInverted,
    disabled,
    extend,
    titleIsHovered,
    lowercaseText,
    subtle,
    trackEventLabel,
    trackData,
    trackEvent,
    trackEventAction,
    ...restProps
  } = props;

  return (
    <Block
      extend={[
        subtleCSS(!!subtle),
        colorsInverted ? invertColorsCSS(disabled, titleIsHovered) : {},
        arrowCentered ? arrowCenteredCSS : {},
        lowercaseText ? lowercaseTextCSS : {},
        extend,
      ]}
    >
      <Track
        eventAction={trackEventAction}
        eventLabel={trackEventLabel || ''}
        customData={trackData}
        event={trackEvent}
      >
        <VCCUILink
          ref={ref}
          arrow={'arrow' in props ? arrow : 'right'}
          disabled={disabled}
          {...restProps}
        />
      </Track>
    </Block>
  );
});

Link.displayName = 'Link';

const arrowCenteredCSS: ExtendCSS = {
  '& button > span': {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
  },
};

const anchorButtonCSS: ExtendCSS = {
  textTransform: 'none',
  letterSpacing: 'none',
};

const subtleCSS = (isSubtle: boolean): ExtendCSS => ({
  extend: {
    condition: isSubtle,
    style: {
      '& > a': anchorButtonCSS,
      '& > button': anchorButtonCSS,
    },
  },
});

const invertedColorsCSS = ({
  disabled,
  titleIsHovered,
  theme,
}: {
  disabled?: boolean;
  titleIsHovered?: boolean;
  theme: CurrentTheme;
}): ExtendCSS => ({
  color: theme.tokens.linkHoverForeground,
  stroke: theme.tokens.linkHoverForeground,
  outlineColor: theme.tokens.linkHoverForeground,
  extend: [
    {
      condition: !disabled,
      style: {
        ':hover:not(:active)': {
          color: theme.tokens.linkForeground,
          outlineColor: theme.tokens.linkForeground,
          stroke: theme.tokens.linkForeground,
        },
      },
    },
    {
      condition: titleIsHovered,
      style: {
        color: theme.tokens.linkForeground,
        outlineColor: theme.tokens.linkForeground,
        stroke: theme.tokens.linkForeground,
      },
    },
  ],
});

const invertColorsCSS =
  (disabled?: boolean, titleIsHovered?: boolean): ExtendCSS =>
  ({ theme }) => ({
    '& button': invertedColorsCSS({ disabled, titleIsHovered, theme }),
    '& a': invertedColorsCSS({ disabled, titleIsHovered, theme }),
  });

const lowercaseTextCSS: ExtendCSS = {
  '& button': {
    textTransform: 'none',
  },
};
