'use client';

import React, { useRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { useDialogMainSlot } from './DialogMain.utils';

export const DialogMain: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = (props) => {
  const containerRef = useRef<HTMLElement>(null);
  const dialogMainSlotProps = useDialogMainSlot({ ref: containerRef });

  return (
    <article
      ref={containerRef}
      slot="main"
      className={cssMerge(props.className)} // cssMerge is used to ensure className is cleaned up
      {...dialogMainSlotProps}
    >
      {props.children}
    </article>
  );
};
